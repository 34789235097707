*,
*::after,
*::before {
  box-sizing: border-box;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(./fonts/MaterialIcons-Regular.woff2) format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: "liga";
  -moz-font-feature-settings: "liga";
  -moz-osx-font-smoothing: grayscale;
}

//ant design overstyle!!!!!!!!!!!
.ant-radio-wrapper {
  white-space: normal !important;
}
div,
button,
input {
  transition: background 0.2s linear;
}

img {
  background: transparent;
}
table {
  width: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  &.circle {
    -webkit-animation: dash 0.9s ease-in-out;
    animation: dash 0.9s ease-in-out;
  }
  &.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
    animation: dash 0.9s 0.35s ease-in-out forwards;
  }
  &.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
    animation: dash-check 0.9s 0.35s ease-in-out forwards;
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
#nprogress .peg {
  box-shadow: none !important;
}
html[data-theme="dark"] {
  #nprogress .bar {
    background: #177ddc !important;
  }

  ::-webkit-scrollbar-track {
    border-radius: 2px;
    width: 2px;
    background-color: rgba(255, 255, 255, 0.2);
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: white;
  }
}

html[data-theme="light"] {
  #nprogress .bar {
    background: #c44536 !important;
  }
  ::-webkit-scrollbar-track {
    border-radius: 2px;
    width: 2px;
    background-color: "rgb(115 115 115 / 15%)";
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #aeabab;
  }
}

html {
  scrollbar-width: none;
}
